import PropTypes from "prop-types";
import { useEffect } from "react";
import { useRouter } from "next/router";
import * as gtm from "lib/gtag";

const GoogleTagManager = ({ children }) => {
    const router = useRouter();

    useEffect(() => {
        router.events.on("routeChangeComplete", gtm.pageview);
        return () => {
            router.events.off("routeChangeComplete", gtm.pageview);
        };
    }, [router.events]);

    return children;
};

GoogleTagManager.propTypes = {
    children: PropTypes.node.isRequired
};

export default GoogleTagManager;
