import PropTypes from "prop-types";

import { ButtonStyled, ButtonLinkStyled } from "./Button.styled";

function Button(props) {
    const { children, variant, element } = props;
    const isFancy = variant === "regular" || variant === "ghost";

    return element === "a" ? (
        <ButtonLinkStyled variant={variant} {...props}>
            {isFancy ? <span>{children}</span> : children}
        </ButtonLinkStyled>
    ) : (
        <ButtonStyled variant={variant} {...props}>
            {isFancy ? <span>{children}</span> : children}
        </ButtonStyled>
    );
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.string,
    element: PropTypes.string
};

Button.defaultProps = {
    variant: "regular",
    element: "button"
};

export default Button;
