const colors = {
    blue: "#4C697E",
    brightOrange: "#F15B4A",
    darkBlue: "#042339",
    darkOrange: "#D44130",
    green: "#34BC7A",
    lightBlueGrey: "#DAE2E7",
    lightGrey: "#F4F6F7",
    red: "#E83535",
    white: "#FFFFFF"
};

export default colors;
