import { css } from "styled-components";

export const sizes = {
    sm: 480,
    md: 960,
    lg: 1200
};

function smUp(...args) {
    return css`
        @media (min-width: ${sizes.sm}px) {
            ${css(...args)}
        }
    `;
}

function mdUp(...args) {
    return css`
        @media (min-width: ${sizes.md}px) {
            ${css(...args)}
        }
    `;
}

function lgUp(...args) {
    return css`
        @media (min-width: ${sizes.lg}px) {
            ${css(...args)}
        }
    `;
}

const mobileHide = css`
    @media (max-width: ${sizes.md - 1}px) {
        display: none;
    }
`;

const mobileOnly = css`
    @media (min-width: ${sizes.md}px) {
        display: none;
    }
`;

const desktopHide = css`
    @media (min-width: ${sizes.md}px) {
        display: none;
    }
`;

const desktopOnly = css`
    @media (max-width: ${sizes.md - 1}px) {
        display: none;
    }
`;

const media = {
    smUp,
    mobileHide,
    mobileOnly,
    mdUp,
    desktopHide,
    desktopOnly,
    lgUp
};

export default media;
