import { createGlobalStyle } from "styled-components";
import media from "constants/media";

export default createGlobalStyle`
    :root {
        --colors-black: #141110;
        --colors-blue: #4c697e;
        --colors-bright-orange: #f15b4a;
        --colors-dark-blue: #042339;
        --colors-dark-green: #20794e;
        --colors-dark-orange: #d44130;
        --colors-dark-red: #9b2020;
        --colors-green: #34bc7a;
        --colors-light-blue-grey: #dae2e7;
        --colors-light-dark-blue: #1d394d;
        --colors-light-grey: #f4f6f7;
        --colors-red: #e83535;
        --colors-transparent-bright-orange: rgba(241, 91, 74, 0.11);
        --colors-white: #ffffff;
        --colors-box-shadow: rgba(4, 35, 57, 0.1);

        --colors-tertiary-disabled: rgba(35, 33, 34, 0.5);
        --colors-tertiary: #232122;

        --colors-cardNumber-input-backgorund-primary: #dfd2cd;
        --colors-cardNumber-input-background-secondary: #9e979b;
        --colors-cardNumber-input-background-success: rgba(162, 220, 80, 0.1);
        --colors-error: #e83535;
        --colors-helpers-errorBg: rgba(202, 70, 50, 0.1);
        --colors-helpers-successBg: rgba(162, 220, 80, 0.1);
        --colors-success-disabled: rgba(85, 160, 35, 0.5);
        --colors-success: #a2dc50;
        --colors-successFormText: #55a023;

        --border-radius-extra-small: 4px;
        --border-radius-small: 6px;
        --border-radius-medium: 10px;
        --border-radius-large: 20px;
        --border-radius-extra-large: 25px;

        --fontFamily-primary: "Codec Pro", sans-serif;

        --fontWeights-light: 300;
        --fontWeights-normal: 400;
        --fontWeights-bold: 700;

        --fontSizes-heading1-mobile: 35px;
        --fontSizes-heading1-desktop: 60px;
        --fontSizes-heading2-mobile: 30px;
        --fontSizes-heading2-desktop: 50px;
        --fontSizes-heading3-mobile: 24px;
        --fontSizes-heading3-desktop: 40px;
        --fontSizes-heading4-mobile: 22px;
        --fontSizes-heading4-desktop: 30px;
        --fontSizes-heading5-mobile: 20px;
        --fontSizes-heading5-desktop: 25px;
        --fontSizes-heading6-mobile: 18px;
        --fontSizes-heading6-desktop: 20px;

        --fontSizes-text-mobile: 14px;
        --fontSizes-text-desktop: 14px;

        --animation-easing-standard: cubic-bezier(0.4, 0, 0.2, 1);
        --colors-skeletonScreen-background: #e8ad95;
        --colors-skeletonScreen-shine: #fff;
    }

    * {
        box-sizing: border-box;
    }

    html, body, h1, h2, h3, h4, h5, p, ul, ol {
        margin: 0;
        padding: 0;
    }

    html, body, #__next {
        height: 100%;
        min-height: 100%;
    }

    body, button, input, select, textarea {
        font-family: var(--fontFamily-primary);
    }

    html {
        scroll-behavior: smooth;
    }

    body {
        color: var(--colors-dark-blue);

        &.ReactModal__Body--open {
            overflow: hidden;
        }
    }

    a, button {
        color: inherit;
        font-size: inherit;
        line-height: inherit;

        cursor: pointer;
    }

    button {
        padding: 0;

        background: transparent;

        border: 0;
        outline: none;
    }

    .react-modal {
        position: absolute;
        z-index: 14;


        max-height: 100%;

        overflow: auto;

        background: transparent;
        outline: none;
        opacity: 0;

        transition: 0.4s opacity ease-in-out;

        svg g {
            fill: var(--colors-dark-blue);
        }


        ${media.mdUp`
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            padding: 40px 20px;
        `}

        &-overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 20;

            background: rgba(0, 23, 75, 0.5);
        }

    }

    .ReactModal__Overlay {
        &--after-open .react-modal {
            opacity: 1;
        }

        &--before-close .react-modal {
            opacity: 0;
        }
    }

    .ReactModal__Content--after-open {
        width: 100%;
    }
`;
