/* eslint-disable react/no-danger */
import NextHead from "next/head";
import { useRouter } from "next/router";
import { css } from "styled-components";

function Base() {
    const router = useRouter();
    const pathname = router.asPath;

    return (
        <NextHead>
            <link
                rel="preconnect"
                href={process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL}
                crossOrigin="anonymous"
            />
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="icon" sizes="16x16 32x32 64x64" href="/favicon.ico" />
            <link
                rel="icon"
                type="image/png"
                sizes="196x196"
                href="/favicon-192.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="160x160"
                href="/favicon-160.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="/favicon-96.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="64x64"
                href="/favicon-64.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="114x114"
                href="/favicon-114.png"
            />
            <link rel="apple-touch-icon" href="/favicon-57.png" />
            <link rel="apple-touch-icon" sizes="72x72" href="/favicon-72.png" />
            <link
                rel="apple-touch-icon"
                sizes="144x144"
                href="/favicon-144.png"
            />
            <link rel="apple-touch-icon" sizes="60x60" href="/favicon-60.png" />
            <link
                rel="apple-touch-icon"
                sizes="120x120"
                href="/favicon-120.png"
            />
            <link rel="apple-touch-icon" sizes="76x76" href="/favicon-76.png" />
            <link
                rel="apple-touch-icon"
                sizes="152x152"
                href="/favicon-152.png"
            />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/favicon-180.png"
            />
            <meta name="theme-color" content="#00174B" />
            <meta name="msapplication-TileColor" content="#FFFFFF" />
            <meta name="msapplication-TileImage" content="/favicon-144.png" />
            <meta name="msapplication-config" content="/browserconfig.xml" />
            <meta
                property="og:image"
                content={`${process.env.NEXT_PUBLIC_BASE_URL}/images/seo-banner-1200x600.jpg`}
            />
            <meta property="og:image:height" content="600" />
            <meta
                property="twitter:image"
                content={`${process.env.NEXT_PUBLIC_BASE_URL}/images/seo-banner-1200x630.jpg`}
            />
            <link
                rel="dns-prefetch preconnect"
                as="script"
                href="https://www.googletagmanager.com/"
                crossOrigin="anonymous"
            />

            {/* Fonts  */}
            <link
                rel="prefetch"
                href="/fonts/CodecPro-Light.woff2"
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                href="/fonts/CodecPro-Regular.woff2"
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                href="/fonts/CodecPro-Bold.woff2"
                as="font"
                type="font/woff2"
                crossOrigin="anonymous"
            />
            {/* End Fonts */}

            <link
                rel="canonical"
                href={`${process.env.NEXT_PUBLIC_BASE_URL}${pathname}`}
            />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
            />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="default"
            />
            <meta name="format-detection" content="telephone=no" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta name="msapplication-tap-highlight" content="no" />
            <meta name="theme-color" content="#FFFFFF" />
            <meta name="twitter:card" content="summary" />
            <meta
                name="twitter:url"
                content={`${process.env.NEXT_PUBLIC_BASE_URL}${pathname}`}
            />
            <meta
                name="google-site-verification"
                content="4lS-wgOC68i_FTwsEZMmIrAByqjVlEYOcIyz1YTMtwI"
            />
            <style
                dangerouslySetInnerHTML={{
                    __html: css`
                        @font-face {
                            font-weight: 300;
                            font-family: "Codec Pro";
                            font-display: swap;

                            /* prettier-ignore */
                            src: local("Codec Pro"), url("/fonts/CodecPro-Light.woff2") format("woff2");
                        }
                        @font-face {
                            font-weight: normal;
                            font-family: "Codec Pro";
                            font-display: swap;

                            /* prettier-ignore */
                            src: local("Codec Pro"), url("/fonts/CodecPro-Regular.woff2") format("woff2");
                        }
                        @font-face {
                            font-weight: bold;
                            font-family: "Codec Pro";
                            font-display: swap;

                            /* prettier-ignore */
                            src: local("Codec Pro"), url("/fonts/CodecPro-Bold.woff2") format("woff2");
                        }
                    `
                }}
            />
        </NextHead>
    );
}

export default Base;
