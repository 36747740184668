import styled, { css } from "styled-components";
import media from "constants/media";

const regularGhostStates = css`
    position: relative;

    overflow: hidden;

    transition: 0.2s all ease-in-out;

    &:before {
        position: absolute;
        top: 0;
        left: 0;

        width: 40%;
        height: 100%;

        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;

        transform: translateX(-100%);

        transition: 0.2s all ease-in-out;

        content: "";
    }

    &:hover,
    &:focus {
        box-shadow: 0 2px 7px 0 rgba(241, 91, 74, 0.5);

        transform: translateY(-4px);
    }

    &:active {
        &:before {
            transform: translateX(0);
        }
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }

    span {
        position: relative;
        z-index: 2;
    }
`;

const regularButtonStyles = css`
    height: 40px;

    padding: 8px 20px;

    color: var(--colors-white);
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;

    background: var(--colors-bright-orange);

    border-radius: 25px;

    ${media.mdUp`
        height: 50px;

        padding: 14px 28px 16px;

        font-size: 16px;
    `}

    ${regularGhostStates};

    &:before {
        background: var(--colors-white);

        opacity: 0.1;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }
`;

const ghostButtonStyles = css`
    height: 40px;

    padding: 8px 20px;

    color: var(--colors-bright-orange);
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;

    background: transparent;

    border: 1px solid var(--colors-bright-orange);
    border-radius: 25px;

    ${media.mdUp`
        height: 50px;

       padding: 14px 28px 16px;

        font-size: 16px;
    `}

    ${regularGhostStates};

    &:before {
        background: var(--colors-bright-orange);

        opacity: 0.1;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }
`;

const underlineButtonStyles = css`
    position: relative;

    color: var(--colors-bright-orange);

    font-weight: bold;
    font-size: 14px;
    line-height: 30px;

    transition: 0.3s all ease-in-out;

    &:after {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 1px;

        background: var(--colors-bright-orange);

        transition: 0.3s all ease-in-out;

        content: "";
    }

    &:hover,
    &:focus {
        &:after {
            bottom: -5px;
        }
    }

    &:active {
        color: var(--colors-red);

        &:after {
            background: var(--colors-red);
        }
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }
`;

const minimalButtonStyles = css`
    color: inherit;

    font-weight: inherit;
    font-size: inherit;

    svg,
    img {
        margin-right: 10px;

        transition: 0.3s all ease-in-out;
    }

    &:hover,
    &:focus {
        svg,
        img {
            margin-right: 5px;
        }
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }
`;

const whiteButtonStyles = css`
    padding: 8px 20px 7px;

    color: var(--colors-dark-blue);

    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0;
    text-align: center;

    background-color: var(--colors-white);
    border-radius: var(--border-radius-extra-large);

    ${media.mdUp`
        font-size: 16px;
        line-height: 20px;
        padding: 16px 27px 14px 28px;
    `}

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }
`;

const ButtonStyles = css`
    display: inline-flex;
    align-items: center;

    margin: 0;
    padding: 0;

    background: transparent;
    border: 0;
    outline: 0;

    cursor: pointer;

    user-select: none;

    ${({ variant }) =>
        (variant === "regular" && regularButtonStyles) ||
        (variant === "ghost" && ghostButtonStyles) ||
        (variant === "underline" && underlineButtonStyles) ||
        (variant === "minimal" && minimalButtonStyles) ||
        (variant === "white" && whiteButtonStyles)}
`;

export const ButtonStyled = styled.button`
    ${ButtonStyles}
`;

export const ButtonLinkStyled = styled.a`
    text-decoration: none;

    ${ButtonStyles}
`;
