import PropTypes from "prop-types";
import colors from "constants/colors";

function IconClose({ fill }) {
    return (
        <svg viewBox="0 0 36 35">
            <g fill={fill}>
                <path d="M32.989 0L35 2.011 19.511 17.5 35 32.989 32.989 35 17.5 19.511 2.011 35 0 32.989 15.489 17.5 0 2.011 2.011 0 17.5 15.489 32.989 0z" />
            </g>
        </svg>
    );
}

IconClose.propTypes = {
    fill: PropTypes.string
};

IconClose.defaultProps = {
    fill: colors.brightOrange
};

export default IconClose;
