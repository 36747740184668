import PropTypes from "prop-types";
import { createContext, useContext, useMemo } from "react";
import useMedia from "use-media";
import { sizes } from "constants/media";

const mediaQueries = {
    mobile: `(max-width: ${sizes.md - 1}px)`,
    desktop: `(min-width: ${sizes.md}px)`
};

const MediaQueryContext = createContext(null);

export function useMediaQueryContext() {
    const context = useContext(MediaQueryContext);

    if (!context) {
        throw new Error(
            "useMediaQueryContext should only be used with a MediaQueryProvider"
        );
    }

    return context;
}

function MediaQueryProvider({ children, defaultView }) {
    const mobileView = useMedia(mediaQueries.mobile, defaultView === "mobile");
    const desktopView = useMedia(
        mediaQueries.desktop,
        defaultView === "desktop"
    );

    const value = useMemo(
        () => ({ mobileView, desktopView }),
        [mobileView, desktopView]
    );

    return (
        <MediaQueryContext.Provider value={value}>
            {children}
        </MediaQueryContext.Provider>
    );
}

MediaQueryProvider.propTypes = {
    children: PropTypes.node.isRequired,
    defaultView: PropTypes.oneOf(["mobile", "desktop"])
};

MediaQueryProvider.defaultProps = {
    defaultView: "mobile"
};

export default MediaQueryProvider;
