import PropTypes from "prop-types";
import { ApolloProvider } from "@apollo/client/react/context";
import { useApollo } from "lib/apolloClient";
import MediaQueryProvider from "lib/mediaQueryContext";

import GlobalStyle from "theme/global-styles";
import BaseHead from "components/shared/head/base/Base";
import CookieConsent from "components/cookieConsent/CookieConsent";
import GoogleTagManager from "components/googleTagManager/GoogleTagManager";

export default function App({ Component, pageProps, err }) {
    const apolloClient = useApollo(pageProps);

    return (
        <>
            <GlobalStyle />
            <BaseHead />
            <MediaQueryProvider>
                <ApolloProvider client={apolloClient}>
                    <GoogleTagManager>
                        <Component {...pageProps} err={err} />
                        <CookieConsent />
                    </GoogleTagManager>
                </ApolloProvider>
            </MediaQueryProvider>
        </>
    );
}

App.defaultProps = {
    Component: undefined,
    pageProps: undefined,
    err: null
};

App.propTypes = {
    Component: PropTypes.any,
    pageProps: PropTypes.any,
    err: PropTypes.any
};
